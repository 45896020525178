import React, { Fragment, useState, useEffect, useRef } from "react";

import { Dialog, Transition } from '@headlessui/react'


// note that this needs to have a focusable element in "children" otherwise there's a weird FocusTrap error
export default function Modal({children, close, widthClass}) {
  // const [open, setOpen] = useState(true)

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-30 inset-0 overflow-y-auto"
        open={true}
        onClose={() => close()}  // don't need to close. controling modal open state in parent
      >
        <div className="fixed h-screen w-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="z-30 absolute h-full w-full inset-0 bg-black bg-opacity-10 transition-opacity"/>
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-0 scale-95"
            enterTo="opacity-100 translate-y-0 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 scale-100"
            leaveTo="opacity-0 translate-y-0 scale-95"
          >
            <div className="absolute z-40 h-screen w-screen flex items-center justify-center min-h-screen px-4 py-20 text-center block">
              <div className="z-30 absolute h-full w-full inset-0 bg-black bg-opacity-80 transition-opacity"
                onClick={(e) => close()}
              ></div>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="z-40 inline-block align-middle h-screen" aria-hidden="true">
                &#8203;
              </span>
              <div className={`z-40 inline-block bg-white text-gray-500 rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-4 align-middle w-full ${widthClass || 'max-w-lg p-8'}`}>
                { children }
              </div>
            </div>
          </Transition.Child>

          
        </div>
      </Dialog>
    </Transition.Root>
  )
}

