import React, { useState } from "react"
import { Link, navigate, graphql } from "gatsby"

// import { Dialog, Transition } from '@headlessui/react'
// import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'

import ReactTooltip from 'react-tooltip';

import Layout from "../../components/common/layout"
import SEO from "../../components/common/seo"
import Modal from "../../components/common/modal";
import Image from "../../components/common/image"
import PaymentLink from "../../components/promotions/paymentLink"
import TrafficChart from "../../components/promotions/trafficChart"

import { communityPromotionLink, communityGoldSubscriptionLink } from "../../components/promotions/constants"


// submit page with a modal if they go to /submit/add/
const SubmitPage = ({path, data}) => {
  const estimates = data.allEstimates.nodes;
  const statusesToInclude = ['Free w/o Reviews', 'Free w/ Reviews', 'Featured 1x', 'Featured 2x', 'Gold Membership', 'Gold + Featured'];

  // test form is just for testing
  const testFormUrl = 'https://docs.google.com/forms/d/e/1FAIpQLScQKjOqPoO94io-8qWJKHaRH-LXNBlaWwh4a_32OO6t3WjWQg/viewform?embedded=true'
  const realFormUrl = 'https://docs.google.com/forms/d/e/1FAIpQLScBwE20N9N4OrxtXkeK0e4VIBpC-ssJNl-s0dutemDirSI8MA/viewform?embedded=true'
  const isProd = process.env.NODE_ENV !== 'development';
  const formUrl = isProd ? realFormUrl : testFormUrl;
  const [gformLoadCount, setgformLoadCount] = useState(0);  // to track redirect for google form
  const showFormModal = path && path.includes('/add') ? true : false; // show modal if it's at the "add" url

  // when the iframe reloads its b/c they completed the form
  const onIframeLoad = (e) => {
    const iframe = e.target;
    const newCount = gformLoadCount + 1;
    setgformLoadCount(newCount)

    // sometimes will navigate if they do something else in the form (like clear) but should be good for most use-cases
    if(newCount > 1){
      navigate('/submit/complete/')
    }
  }

  return (
    <Layout pageTitle="Add your community" enableShare={true} >
      <SEO title="Add your community to the Hive Index" description="Grow your online community by submitting it to be listed on The Hive Index."
        path={'/submit/'} generateImage={true}
      />

      

      <div className="relative mt-0">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center justify-between">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900 flex items-center">
            <div>Listing Options</div>
          </h2>
        </div>
      </div>
      <div className="mt-2 text-gray-500">
        The Hive Index is the destination of thousands of people a day who are looking for a new community to join. It could be yours!
      </div>

      <div className="md:flex md:space-x-8 space-y-8 md:space-y-0 mt-4">
        <div className="flex-1 rounded-3xl ring-1 ring-gray-200">
          <div className="p-6 sm:p-8">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Free Listing</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Increase your community exposure by listing on up to 2 relevant <Link to="/topics/" className="font-semibold underline">topic</Link> pages.
            </p>
          </div>
          <div className="-mt-2 p-2">
            <div className="rounded-2xl bg-yellow-50 py-12 text-center ring-1 ring-inset ring-yellow-100 lg:flex lg:flex-col lg:justify-center lg:py-12">
              <div className="mx-auto max-w-xs px-1">
                <p className="text-base font-semibold text-gray-600">Free forever</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">$0</span>
                  {/*<span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span>*/}
                </p>
                <Link to='/submit/add/'
                  // href={formUrl} target="_blank"
                  className={`block w-full rounded-md bg-rose-600 px-5 py-3 text-center cursor-pointer text-base font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600 mt-10`}
                  // style={{background: '#FE5F55', color: 'white', fontSize: '14px', padding: '10px 15px', borderRadius: '3px', border: 'none', cursor: 'pointer'}}
                >
                  Submit community details
                </Link>
                <p className="mt-6 text-sm leading-5 text-gray-600">
                 Can be listed on up to 2 topic pages only
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 rounded-3xl ring-1 ring-gray-200">
          <div className="p-6 sm:p-8">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Featured (1 month)</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Get featured at the top of 1 topic page for a 30-day burst of new members.
            </p>
          </div>
          <div className="-mt-2 p-2">
            <div className="rounded-2xl bg-yellow-50 py-12 text-center ring-1 ring-inset ring-yellow-100 lg:flex lg:flex-col lg:justify-center lg:py-12">
              <div className="mx-auto max-w-xs px-1">
                <p className="text-base font-semibold text-gray-600">One-time fee</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">$99</span>
                </p>
                <PaymentLink
                  url={communityPromotionLink}
                  params={{'utm_content': 'community_promotion'}}
                  title="Promote your community"
                  additionalClassName="mt-10"
                />
                <p className="mt-6 text-sm leading-5 text-gray-600">
                 Comes with $1 CPC guarantee (99+ clicks)
                </p>
              </div>
            </div>
          </div>
        </div>


        <div className="flex-1 rounded-3xl ring-1 ring-gray-200">
          <div className="p-6 sm:p-8">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Gold Membership</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              List on up to 6 topic pages related to your community for more exposure.
            </p>
          </div>
          <div className="-mt-2 p-2">
            <div className="rounded-2xl bg-yellow-50 py-12 text-center ring-1 ring-inset ring-yellow-100 lg:flex lg:flex-col lg:justify-center lg:py-12">
              <div className="mx-auto max-w-xs px-1">
                <p className="text-base font-semibold text-gray-600">Yearly Subscription</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">$390<span className="opacity-50 text-3xl"> /yr</span></span>
                </p>
                <PaymentLink
                  url={communityGoldSubscriptionLink}
                  params={{'utm_content': 'gold_membership'}}
                  title="Level up your listing"
                  additionalClassName="mt-10"
                />
                <p className="mt-6 text-sm leading-5 text-gray-600">
                 Gold listings get ~10x traffic of free listings
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative mt-8">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center justify-between">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900 flex items-center">
            <div>Traffic Stats</div>
            <span className="ml-2 opacity-50 text-sm">Averages from past month, refreshed weekly</span>
          </h2>
        </div>
      </div>
      <div className="mt-4 text-gray-500">
        <ReactTooltip id={`table-tooltip`} place="top" effect="solid" backgroundColor="black" textColor="white"
          className="max-w-xl"
        />
        <table>
          <thead>
            <tr>
              <th scope="col" className="pr-4 md:pr-8 text-left font-semibold text-gray-900"
                data-for="table-tooltip" data-tip="Listing type for these monthly stats"
              >
                <span className="border-b-2 border-yellow-400 border-dotted">Listing Type</span>
              </th>
              <th scope="col" className="pr-4 md:pr-8 text-left font-semibold text-gray-900"
                data-for="table-tooltip" data-tip="Average clicks out to a community for this type of listing"
              >
                <span className="border-b-2 border-yellow-400 border-dotted">Clicks/mo</span>
              </th>
              <th scope="col" className="pr-4 md:pr-8 text-left font-semibold text-gray-900 hidden sm:table-cell"
                data-for="table-tooltip" data-tip="Monthly cost for this type of listing"
              >
                <span className="border-b-2 border-yellow-400 border-dotted">Cost/mo</span>
              </th>
              <th scope="col" className="pr-4 md:pr-8 text-left font-semibold text-gray-900"
                data-for="table-tooltip" data-tip="Average cost per click for this type of listing"
              >
                <span className="border-b-2 border-yellow-400 border-dotted">CPC</span>
              </th>
              {/*<th scope="col" className="pr-4 md:pr-8 text-left font-semibold text-gray-900 hidden sm:table-cell"
                data-for="table-tooltip" data-tip="How many monthly pageviews of a community listing"
              >
                <span className="border-b-2 border-yellow-400 border-dotted">Views/mo</span>
              </th>
              <th scope="col" className="pr-4 md:pr-8 text-left font-semibold text-gray-900 hidden sm:table-cell"
                data-for="table-tooltip" data-tip="How many unique people view a community on the Hive Index"
              >
                <span className="border-b-2 border-yellow-400 border-dotted">Visitors/mo</span>
              </th>*/}
              
              <th scope="col" className="pr-4 md:pr-8 text-left font-semibold text-gray-900 hidden sm:table-cell"
                data-for="table-tooltip" data-tip="Typical traffic pattern for this type of listing"
              >
                <span className="border-b-2 border-yellow-400 border-dotted">Pattern</span>
              </th>
              {/*<th scope="col" className="pr-4 md:pr-8 text-left font-semibold text-gray-900 hidden sm:table-cell"
                data-for="table-tooltip" data-tip="What percent of visitors turn to clicks"
              >
                <span className="border-b-2 border-yellow-400 border-dotted">CTR</span>
              </th>*/}
            </tr>
          </thead>
          <tbody>
            {estimates.filter(e => statusesToInclude.includes(e.status)).map(e => (
              <tr key={e.status}>
                <td className="py-2 pr-4 md:pr-12">
                  <div className="font-semibold">{e.status}</div>
                  <div className="text-sm opacity-75">{e.description}</div>
                </td>
                <td className="py-2 pr-4 md:pr-12">{e.leads__mo}</td>
                <td className="py-2 hidden sm:table-cell">${e.cost_Mo}</td>
                <td className="py-2 pr-4 md:pr-12">${e.cpc.toFixed(2)}</td>
                {/*<td className="py-2 hidden sm:table-cell">{e.views__mo}</td>
                <td className="py-2 hidden sm:table-cell">{e.visitors__mo}</td>*/}
                
                <td className="py-2 hidden sm:table-cell">
                  <TrafficChart status={e.status} />
                </td>
                {/*<td className="py-2 hidden sm:table-cell">{e.ctr}</td>*/}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="relative mt-8">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center justify-between">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900 flex items-center">
            <div>Requirements</div>
          </h2>
        </div>
      </div>
      <div className="mt-2 text-gray-500">
        <ul
          role="list"
          className="mt-4 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-4 sm:gap-6"
        >
          <li className="flex gap-x-3">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
              className="h-6 w-5 flex-none text-rose-600"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
            Your community has at least 10 active members
          </li>
          <li className="flex gap-x-3">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
              className="h-6 w-5 flex-none text-rose-600"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
            Does not promote NSFW or violent content
          </li>
          <li className="flex gap-x-3">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
              className="h-6 w-5 flex-none text-rose-600"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
            <div>Have some of the <Link to="/features/" className="font-semibold underline">features</Link> of a community (chat/forum minimum)</div>
          </li>
          <li className="flex gap-x-3">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
              className="h-6 w-5 flex-none text-rose-600"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
            <div>Be related to some of the <Link to="/topics/" className="font-semibold underline">topics</Link> found on the Hive Index</div>
          </li>
        </ul>
      </div>

      <div className="relative mt-8">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center justify-between">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900 flex items-center">
            <div>Testimonials</div>
          </h2>
        </div>
      </div>
      <div className="mt-4 text-gray-500 md:flex md:space-x-4 ">
        <p className="flex-1 mb-2">
          <b>"It turned out to be a huge success story and TheHiveIndex became one of our key sources of new members. Can't recommend enough and thank you for helping us reach new heights."</b>
          <br/>- Ophir from <Link to="/communities/furlough-community/" className="underline cursor-pointer">Furlough</Link>
        </p>
        <p className="flex-1 mb-2">
          <b>"Hive Index is the best source of referral traffic to grow your community. Visitors coming from our listing are highly converting ones! It's one of a few marketing channels with a positive ROI."</b>
          <br/>- Ivan from <Link to="/communities/lunadio/" className="underline cursor-pointer">Lunadio</Link>
        </p>
        {/*<p className="mb-2">
          <b>"The value makes sense! I'm down for another month, or can you do 3 or 6?"</b>
          <br/>- Anthony from <Link to="/communities/indie-worldwide/" className="underline cursor-pointer">Indie Worldwide</Link>
        </p>*/}
        <p className="flex-1 mb-2">
          <b>"If you are running a community, The HiveIndex Gold Membership is a deal you must grab. We have been sponsoring for our MicroSaaSHQ community. Drives traffic consistently. I would highly recommend."</b>
          <br/>- Upen from <Link to="/communities/micro-saas-hq/" className="underline cursor-pointer">MicroSaasHQ</Link>
        </p>
        <p className="flex-1 mb-2">
          <b>"If you want a source of good traffic for your community this is well worth a look. For quite a while Hive Index has been our #1 source of traffic."</b>
          <br/>- Simon from <Link to="/communities/hacker-cabin/" className="underline cursor-pointer">Hacker Cabin</Link>
        </p>
      </div>


      <div className="relative mt-8">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center justify-between">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900 flex items-center">
            <div>FAQ</div>
          </h2>
        </div>
      </div>
      <div className="mt-2 text-gray-500">
        
        <p className="mb-2">
          <b>My community hasn't launched yet, is that OK?</b><br/>
          Please submit your community after you have 10+ founding members in it
        </p>
        {/*<p className="mb-2">
          <b>Can I submit someone else's community?</b><br/>
          Yes, that is welcome and appreciated!
        </p>
        <p className="mb-2">
          <b>Can I submit a paid community?</b><br/>
          Yes, as long as your community has a landing page and you select the "paid" feature when submitting it.
        </p>
        <p className="mb-2">
          <b>Does the online community need to be on a platform like Reddit/Slack, or can it be a standalone website?</b><br/>
          Online communities on any platform (or no platform) are both allowed to be listed.
        </p>*/}
        <p className="mb-2">
          <b>Can I buy a promotion on 2 topic pages?</b><br/>
          Yes!
        </p>
        <p className="mb-2">
          <b>Can I buy both Gold and run a promotion?</b><br/>
          Yes!
        </p>
        <p className="mb-2">
          <b>Can my community platform/tool/business sponsor the site?</b><br/>
          Yes, read more about <Link to="/partners/sponsor/" className="font-semibold underline text-rose-600">the site sponsorship here</Link>.
        </p>
        <p className="mb-2">
          <b>How much traffic will I get?</b><br/>
          Please see the table above or <Link to="/partners/traffic/" className="font-semibold underline text-rose-600">traffic stats</Link> for an estimate.
        </p>
        <p className="mb-2">
          <b>Any other questions?</b><br/>
          <a href="mailto:contact@thehiveindex.com" className="font-semibold underline text-rose-600">Send an email</a> and get in touch!
        </p>
      </div>


      {/* submit modal */}
      {showFormModal ? (
        <Modal close={() => navigate('/submit/')}
          widthClass={"max-w-4xl"}
        >
          <iframe id="gform" src={formUrl} onLoad={onIframeLoad}
            frameBorder="0" marginHeight="0" marginWidth="0" className="w-full h-screen"
          />
        </Modal>
      ) : ''}

    </Layout>
  )
}


export const query = graphql`
query {
  allEstimates: allGoogleSpreadsheetSourceEstimates(sort: {fields: order, order: ASC}) {
    totalCount
    nodes {
      status
      cost_Mo
      views__mo
      visitors__mo
      leads__mo
      description
      cpc
      ctr
    }
  }
}
`

export default SubmitPage

